import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import { H1, H3, P } from "../components/ui/text"

const NotFoundPage = () => {
  return (
    <Layout>
      <H1>Page not found</H1>
      <P>
        Sorry{" "}
        <span role="img" aria-label="Pensive emoji">
          😔
        </span>{" "}
        <br />
        we couldn’t find what you were looking for.
        <br />
        <Link to="/">Go home</Link>.
      </P>
    </Layout>
  );
};

export default NotFoundPage
