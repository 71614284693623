import React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"
import { motion } from "framer-motion"

export const H1 = styled(motion.h1)`
  padding: 0;
  margin: 0;
  font-size: 28px;
  line-height: 32px;
  font-weight: bold;
  color: ${(props) => props.color || `#323232`};
  margin-bottom: 14px;

  ${up("md")} {
    font-size: 36px;
    line-height: 46px;
  }
`

export const H2 = styled.h2`
  padding: 0;
  margin: 0;
  font-size: 24px;
  line-height: 30px;
  font-weight: bold;
`

export const H3 = styled.h3`
  padding: 0;
  margin: 0;
  font-size: 22px;
  line-height: 28px;
  font-weight: normal;
`

export const P = styled.p`
  padding: 0;
  margin: 0;
  font-size: 16px;
  line-height: 21px;
  font-weight: normal;
  color: ${(props) => props.color || `#666666`};
`

export const TERMS = styled.p`
  padding: 0;
  margin: 0;
  font-size: 10px;
  line-height: 12px;
  font-weight: normal;
  color: ${(props) => props.color || `#424B5A`};
`
