import React from "react"
import PropTypes from "prop-types"
import styled, { ThemeProvider, createGlobalStyle } from "styled-components"
import { up } from "styled-breakpoints"
import { Helmet } from "react-helmet"

import Meta from "./meta"

const theme = {
  breakpoints: {
    xs: '0px',
    sm: '370px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    xxl: '1600px',
  }
}

const Container = styled.div`
  margin-left:auto;
  margin-right:auto;
  text-align:center;
  position: relative;
  
  ${up('lg')}{
    width:1010px;
  }
`

const Page = styled.div`
  width:100%;
  height:100%;
  padding-top:32px;
  
  ${up('md')}{
    padding-top:48px;
  }
`

const GlobalStyles = createGlobalStyle`

  body{
     background:transparent;
  }

`

const Layout = ({ children }) => {

  return (
    <Page>
      <Meta />
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Container>{children}</Container>
      </ThemeProvider>
      <Helmet>
        <script 
          src="/js/iframeResizer.contentWindow.min.js"
          type="text/javascript"
          defer
        ></script>
      </Helmet>
    </Page>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout